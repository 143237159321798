import { useStaticQuery, graphql } from "gatsby"

export const LatestEditorialsData = () => {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query latestEditorials {
        wpgraphql {
          editorials(first: 17) {
            pageInfo {
              total
            }
            nodes {
              title
              colourFeatureImage
              content
              link
              date
              excerpt
              featuredImage {
                sourceUrl
                srcSet
                mediaDetails {
                  width
                  height
                }
              }
              categories(first: 3) {
                nodes {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    `
  )

  return {
    posts: wpgraphql.editorials.nodes,
    count: wpgraphql.editorials.pageInfo.total
  }
}
